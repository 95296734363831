import React, { useState, useEffect } from "react";

const PopularRestaurant = () => {
  const [topHotels, setTopHotels] = useState(null);
  useEffect(() => {
    fetch("https://web-production-e68d.up.railway.app/get_top_hotel")
      .then((res) => res.json())
      .then((data) => {
        console.log(data?.body[0].hotel_name);
        setTopHotels(data);
      })
      .catch(console.error);
  }, []);
  return (
    <section>
      <div className="container py-5">
        <h2 className="section-title py-4">
          Popular <span className="primary-text">Restaurants</span> in City
        </h2>
        <div className="row">
          {topHotels?.body?.map((hotel, i) => (
            <div
              key={i}
              className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 py-1"
            >
              <div className="rest-card p-3 bg-color w-100 h-100">
                <div className="card-img text-center">
                  <img
                    alt=""
                    className="img-fluid"
                    height="84"
                    width="84"
                    src={hotel?.hotel_logo}
                  ></img>
                </div>
                <h5 className="pt-4 mb-0">{hotel?.hotel_name}</h5>
                <div className="ratings pb-2">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star-half-o"></i>
                  <i className="fa fa-star-o"></i>
                </div>
              </div>
            </div>
            // <div className="row px-lg-5">

            //   {/* <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 py-1">
            //     <div className="rest-card p-3 bg-color w-100 h-100">
            //       <div className="card-img text-center">
            //         <img
            //           alt=""
            //           className="img-fluid"
            //           height="84"
            //           width="84"
            //           src="../assets/domi.png"
            //         ></img>
            //       </div>
            //       <h5 className="pt-4 mb-0">Domino's pizza</h5>
            //       <div className="ratings pb-2">
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star-half-o"></i>
            //         <i className="fa fa-star-o"></i>
            //       </div>
            //     </div>
            //   </div>
            //   <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 py-1">
            //     <div className="rest-card p-3 bg-color w-100 h-100">
            //       <div className="card-img text-center">
            //         <img
            //           alt=""
            //           className="img-fluid"
            //           height="84"
            //           width="84"
            //           src="../assets/pizzahut.png"
            //         ></img>
            //       </div>
            //       <h5 className="pt-4 mb-0">Pizza Hut</h5>
            //       <div className="ratings pb-2">
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star-half-o"></i>
            //         <i className="fa fa-star-o"></i>
            //       </div>
            //     </div>
            //   </div>
            //   <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 py-1">
            //     <div className="rest-card p-3 bg-color w-100 h-100">
            //       <div className="card-img text-center">
            //         <img
            //           alt=""
            //           className="img-fluid"
            //           height="84"
            //           width="84"
            //           src="../assets/bk.png"
            //         ></img>
            //       </div>
            //       <h5 className="pt-4 mb-0">Burger king</h5>
            //       <div className="ratings pb-2">
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star-half-o"></i>
            //         <i className="fa fa-star-o"></i>
            //         <i className="fa fa-star-o"></i>
            //       </div>
            //     </div>
            //   </div>
            //   <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 py-1">
            //     <div className="rest-card p-3 bg-color w-100 h-100">
            //       <div className="card-img text-center">
            //         <img
            //           alt=""
            //           className="img-fluid"
            //           height="84"
            //           width="84"
            //           src="../assets/Kfc.png"
            //         ></img>
            //       </div>
            //       <h5 className="pt-4 mb-0">Kfc</h5>
            //       <div className="ratings pb-2">
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star-half-o"></i>
            //         <i className="fa fa-star-o"></i>
            //       </div>
            //     </div>
            //   </div>
            //   <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 py-1">
            //     <div className="rest-card p-3 bg-color w-100 h-100">
            //       <div className="card-img text-center">
            //         <img
            //           alt=""
            //           className="img-fluid"
            //           height="84"
            //           width="84"
            //           src="../assets/mc.png"
            //         ></img>
            //       </div>
            //       <h5 className="pt-4 mb-0">McDonald's</h5>
            //       <div className="ratings pb-2">
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star"></i>
            //         <i className="fa fa-star-half-o"></i>
            //         <i className="fa fa-star-o"></i>
            //       </div>
            //     </div>
            //   </div> */}
            // </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PopularRestaurant;
