import React from "react";

const contact = () => {
  return (
    <section>
      <div className="container py-5">
        <img alt="" className="img-fluid" src="../assets/v2.png"></img>
        <div className="row py-4">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <img
              alt=""
              className="img-fluid"
              src="../assets/contact-vid.webp"
            ></img>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="contact-content">
              <h3 className="contact-title">
                Supercharge your business with App Development & Web Development
              </h3>
              <p className="para-text">
                Building apps is complex. We simplify, streamline, and enhance
                the journey, So you can enjoy the process and achieve success
                with confidence.
              </p>
              <a
                href="https://codedeskstudio.com/schedule-booking/"
                className="contact-btn text-decoration-none"
                target="_blank"
                rel="noreferrer"
                type="button"
              >
                <span className="px-2">
                  <img
                    alt=""
                    className="img-fluid"
                    src="../assets/wave.webp"
                  ></img>
                </span>{" "}
                Hi, Schedule Your 30-Minute Free Strategy Session
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container services py-5">
        <h2 className="section-title text-center">
          All Our Comprehensive Services
        </h2>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="container">
                <div className="services-card p-3 bg-color my-4">
                  <div className="row">
                    <div className=" col-12 col-md-4 col-lg-4">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/ui-ux.webp"
                      ></img>
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                      <h5>Branding & UI/UX Design</h5>
                      <p className="para-text">
                        Brand strategy is all about developing a unique identity
                        that distinguishes your business from
                      </p>
                      <h6>Read more</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="container">
                <div className="services-card p-3 bg-color my-4">
                  <div className="row">
                    <div className=" col-12 col-md-4 col-lg-4">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/admin.webp"
                      ></img>
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                      <h5>Admin Panel Development</h5>
                      <p className="para-text">
                        Web design & development is an umbrella term that
                        describes the process of creating a website
                      </p>
                      <h6>Read more</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="container">
                <div className="services-card p-3 bg-color my-4">
                  <div className="row">
                    <div className=" col-12 col-md-4 col-lg-4">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/app.webp"
                      ></img>
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                      <h5>iOS App Development</h5>
                      <p className="para-text">
                        Digital marketing potential customers using the internet
                        & other forms of digital communication
                      </p>
                      <h6>Read more</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="container">
                <div className="services-card p-3 bg-color my-4">
                  <div className="row">
                    <div className=" col-12 col-md-4 col-lg-4">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/android.webp"
                      ></img>
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                      <h5>Android App Development</h5>
                      <p className="para-text">
                        Custom Software Development is the process of
                        conceptualizing, designing, building & deploying
                      </p>
                      <h6>Read more</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default contact;
