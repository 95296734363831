import React from "react";

const DeliciousDishes = () => {
  return (
    <section className="bg-color">
      <div className="container py-5">
        <h2 className="section-title py-lg-4">
          Wait a <span className="primary-text">minute</span> for delicious.
        </h2>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 py-1">
            <div className="food-card bg-white p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img alt="" className="img-fluid" src="../assets/gb.png"></img>
              </div>
              <h5 className="pt-4">Grilled Fresh Burger</h5>
              <p className="para-text">
                There are many variations of Grilled Fresh Burger with great
                quality ingredients
              </p>
              <p>€ 5.00</p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 py-1">
            <div className="food-card bg-white p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img alt="" className="img-fluid" src="../assets/gb.png"></img>
              </div>
              <h5 className="pt-4">Grilled Fresh Burger</h5>
              <p className="para-text">
                There are many variations of Grilled Fresh Burger with great
                quality ingredients
              </p>
              <p>€ 5.00</p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 py-1">
            <div className="food-card bg-white p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img alt="" className="img-fluid" src="../assets/gb.png"></img>
              </div>
              <h5 className="pt-4">Grilled Fresh Burger</h5>
              <p className="para-text">
                There are many variations of Grilled Fresh Burger with great
                quality ingredients
              </p>
              <p>€ 5.00</p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-3 py-1">
            <div className="food-card bg-white p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img alt="" className="img-fluid" src="../assets/gb.png"></img>
              </div>
              <h5 className="pt-4">Grilled Fresh Burger</h5>
              <p className="para-text">
                There are many variations of Grilled Fresh Burger with great
                quality ingredients
              </p>
              <p>€ 5.00</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeliciousDishes;
