import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./Home/home";
import Contact from "./Contact/contact";
import Restaurant from "./Restaurant/restaurant";
import Menu from "./Menu/Menu";
import DishDetail from "./components/MenuComp/DishDetail";
import Footer from "./components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/restaurant" element={<Restaurant />} />
          <Route exact path="/menu/:id" element={<Menu />} />
          <Route exact path="/dishdetail" element={<DishDetail />} />
          {/* <Route path="*" element={<NoPage />} /> */}
          {/* .......Hotel-routes.................. */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
