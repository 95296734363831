import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const RestaurantsList = () => {
  const [getAllHotel, setAllHotels] = useState(null);
  useEffect(() => {
    fetch(
      "https://web-production-e68d.up.railway.app/get_all_hotel_without_login"
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setAllHotels(data);
      })
      .catch(console.error);
  }, []);
  return (
    <section>
      <div className="restaurant-list container py-5">
        <div className="input-group mb-3 position-relative">
          <div className="search-icon">
            {/* <i className="fa fa-search"></i> */}
            <img alt="" className="im-fluid" src="../assets/search.png"></img>
          </div>
          <input
            type="text"
            className="form-control search-input"
            placeholder="Search..."
            aria-label="search"
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <button className="btn btn-search" type="button">
              Button
            </button>
          </div>
        </div>
        <div className="row">
          {getAllHotel?.body?.map((hotel, i) => (
            <div key={i} className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
              <div className="rest-card p-3 bg-color w-100 h-100">
                <Link
                  className="text-decoration-none"
                  state={hotel}
                  to={`/Menu/${hotel.id}`}
                >
                  <div className="card-img text-center">
                    <img
                      alt=""
                      className="img-fluid"
                      height="84"
                      width="84"
                      src={hotel?.hotel_logo}
                    ></img>
                  </div>
                  <h5 className="pt-4 mb-0">{hotel?.hotel_name}</h5>
                </Link>

                <div className="ratings pb-2">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star-half-o"></i>
                  <i className="fa fa-star-o"></i>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <NavLink className="text-decoration-none" to="/Menu">
                <div className="card-img text-center">
                  <img
                    alt=""
                    className="img-fluid"
                    height="84"
                    width="84"
                    src="../assets/domi.png"
                  ></img>
                </div>
                <h5 className="pt-4 mb-0">Domino's pizza</h5>
              </NavLink>

              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/pizzahut.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">Pizza Hut</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/bk.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">Burger king</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/Kfc.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">Kfc</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/mc.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">McDonald's</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/dev.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">Densire</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/pearl.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">Pearl-Continental</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/avari.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">Avari Hotel</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/pizzahut.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">Pizza Hut</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/Kfc.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">Kfc</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 py-2">
            <div className="rest-card p-3 bg-color w-100 h-100">
              <div className="card-img text-center">
                <img
                  alt=""
                  className="img-fluid"
                  height="84"
                  width="84"
                  src="../assets/mc.png"
                ></img>
              </div>
              <h5 className="pt-4 mb-0">McDonald's</h5>
              <div className="ratings pb-2">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star-half-o"></i>
                <i className="fa fa-star-o"></i>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default RestaurantsList;
