import React from "react";
import HotelDetail from "../components/MenuComp/HotelDetail";
import FullMenu from "../components/MenuComp/FullMenu";

const Menu = () => {
  return (
    <div>
      {" "}
      <HotelDetail />
      <FullMenu />
    </div>
  );
};

export default Menu;
