import React from "react";

const ChooseUs = () => {
  return (
    <section>
      <div className="container py-lg-5">
        <h2 className="section-title text-center py-4">
          Why <span className="primary-text">Choose</span> Us
        </h2>
        <div className="row py-lg-3 px-lg-5">
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 py-1 pe-lg-5">
            <div className="features-card bg-color p-3 bg-color w-100 h-100">
              <div className="card-img ">
                <img
                  alt=""
                  className="img-fluid"
                  src="../assets/icon3.png"
                ></img>
              </div>
              <h5 className="pt-4">Restaurant’s Profile</h5>
              <p className="para-text">
                Explore detailed profiles of restaurants within the app. Get to
                know their ambiance, location and read reviews from other users.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 py-1 pe-lg-5">
            <div className="features-card bg-color p-3 bg-color w-100 h-100">
              <div className="card-img ">
                <img
                  alt=""
                  className="img-fluid"
                  src="../assets/icon2.png"
                ></img>
              </div>
              <h5 className="pt-4">Exclusive Offers</h5>
              <p className="para-text">
                WEBATE allows you to access limited-time offers on a wide range
                of food items , menus & events.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 py-1 pe-lg-5">
            <div className="features-card bg-color p-3 bg-color w-100 h-100">
              <div className="card-img ">
                <img
                  alt=""
                  className="img-fluid"
                  src="../assets/icon1.png"
                ></img>
              </div>
              <h5 className="pt-4">Favorites List</h5>
              <p className="para-text">
                Keep track of your preferred hotels by adding them to your
                Favorites list.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
