import React from "react";

const HotelDetail = () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <img alt="" className="img-fluid" src="../assets/mainimg.png"></img>
            <div className="other-imgs p-4">
              <img
                alt=""
                className="img-fluid px-3"
                src="../assets/smallmainimg.png"
              ></img>
              <img
                alt=""
                className="img-fluid px-3"
                src="../assets/smallmainimg.png"
              ></img>
              <img
                alt=""
                className="img-fluid px-3"
                src="../assets/smallmainimg.png"
              ></img>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="hotel-details">
              <div className="d-flex">
                <img alt="" className="img-fluid" src="../assets/mc2.png"></img>
                <h2 className="fs-1 pt-3">McDonald's</h2>
              </div>
              <div className="ratings py-1">
                <i className="fa fa-star px-1"></i>
                <i className="fa fa-star px-1"></i>
                <i className="fa fa-star px-1"></i>
                <i className="fa fa-star-half-o px-1"></i>
                <i className="fa fa-star-o px-1"></i>
              </div>
              <h5 className="fw-bold">Description:</h5>
              <p className="para-text">
                There are many variations of passages of Lorem Ipsum available
              </p>
              <h5 className="fw-bold">Phone No:</h5>
              <p className="para-text">+1(552) 7658493</p>
              <h5 className="fw-bold">Postal Code:</h5>
              <p className="para-text">5408</p>
              <h5 className="para-text">Address:</h5>
              <p className="para-text">USA the cheese factory </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelDetail;
