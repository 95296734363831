import React from "react";

const DishDetail = () => {
  return (
    <section>
      <div className="container ">
        <div className="d-flex py-4">
          <img alt="" className="img-fluid" src="../assets/mc2.png"></img>
          <h2 className="fs-1 pt-3">McDonald's</h2>
        </div>
        <div className="row py-5">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img
              alt=""
              className="img-fluid"
              src="../assets/mainpatty.png"
            ></img>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="details">
              <h4 className="fs-2">Patty Burger</h4>

              <div className="d-flex justify-content-between">
                <h5 className="fs-3">Price:</h5>
                <p className="fs-3 para-text">€ 5.00</p>
              </div>
              <p className="para-text">
                Extra Value Meal: Make your morning sizzle with McDonald’s
                Chicken Sausage McMuffin with egg breakfast freshly toasted
                English muffin, savory chicken sausage, a slice of melted
                cheese, a freshly cracked Grade A+ egg and a crispy golden hash
                brown. Enjoy it with a steaming hot cup of our delicious coffee
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-color">
        <div className="container py-5">
          <h2 className="fs-2 text-center py-5">More Products</h2>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 py-1">
              <div className="food-card bg-white p-3 bg-color w-100 h-100">
                <div className="card-img text-center">
                  <img
                    alt=""
                    className="img-fluid"
                    src="../assets/gb.png"
                  ></img>
                </div>
                <h5 className="pt-4">Grilled Fresh Burger</h5>
                <p className="para-text">
                  There are many variations of Grilled Fresh Burger with great
                  quality ingredients
                </p>
                <p>€ 5.00</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 py-1">
              <div className="food-card bg-white p-3 bg-color w-100 h-100">
                <div className="card-img text-center">
                  <img
                    alt=""
                    className="img-fluid"
                    src="../assets/gb.png"
                  ></img>
                </div>
                <h5 className="pt-4">Grilled Fresh Burger</h5>
                <p className="para-text">
                  There are many variations of Grilled Fresh Burger with great
                  quality ingredients
                </p>
                <p>€ 5.00</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 py-1">
              <div className="food-card bg-white p-3 bg-color w-100 h-100">
                <div className="card-img text-center">
                  <img
                    alt=""
                    className="img-fluid"
                    src="../assets/gb.png"
                  ></img>
                </div>
                <h5 className="pt-4">Grilled Fresh Burger</h5>
                <p className="para-text">
                  There are many variations of Grilled Fresh Burger with great
                  quality ingredients
                </p>
                <p>€ 5.00</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3 py-1">
              <div className="food-card bg-white p-3 bg-color w-100 h-100">
                <div className="card-img text-center">
                  <img
                    alt=""
                    className="img-fluid"
                    src="../assets/gb.png"
                  ></img>
                </div>
                <h5 className="pt-4">Grilled Fresh Burger</h5>
                <p className="para-text">
                  There are many variations of Grilled Fresh Burger with great
                  quality ingredients
                </p>
                <p>€ 5.00</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default DishDetail;
