import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useParams } from "react-router-dom";

const FullMenu = () => {
  const [hotelData, setHotelData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchHotelData = async () => {
      try {
        const response = await fetch(
          `https://web-production-e68d.up.railway.app/get_category_by_hotel_id?hotel_id=${id}`
        );
        if (response.ok) {
          const data = await response.json();
          setHotelData(data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching hotel data:", error);
      }
    };

    fetchHotelData();
  }, [id]);
  return (
    <section>
      <div className="container py-3">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-3">
            <div className="input-group mb-3 position-relative">
              <div className="search-icon">
                {/* <i className="fa fa-search"></i> */}
                <img
                  alt=""
                  className="im-fluid"
                  src="../assets/search.png"
                ></img>
              </div>
              <input
                type="text"
                className="form-control search-input rounded bg-color"
                placeholder="Search..."
                aria-label="search"
                aria-describedby="basic-addon2"
              />
            </div>
            <div className="search-reults">
              {hotelData?.body?.map((menuItem, i) => (
                <div
                  key={i}
                  className="bg-color px-3 py-2 rounded my-3 cetagory-card"
                >
                  <img
                    alt=""
                    height={40}
                    width={40}
                    className="img-fluid rounded-circle"
                    src={menuItem?.category_image}
                  ></img>
                  <span className="px-3 fs-5">{menuItem?.category_name}</span>
                </div>
              ))}
            </div>
            {/* <div className="search-results">
              <div className=" px-3 py-2 rounded my-3">
                <img className="img-fluid" src="../assets/thumbnail.png"></img>
                <span className="px-3 text-white fs-5">Burgers</span>
              </div>
              <div className=" px-3 py-2 rounded bg-color my-3">
                <img className="img-fluid" src="../assets/thumbnail.png"></img>
                <span className="px-3 fs-5">Burgers</span>
              </div>
              <div className=" px-3 py-2 rounded bg-color my-3">
                <img className="img-fluid" src="../assets/thumbnail.png"></img>
                <span className="px-3 fs-5">Burgers</span>
              </div>
              <div className=" px-3 py-2 rounded bg-color my-3">
                <img className="img-fluid" src="../assets/thumbnail.png"></img>
                <span className="px-3 fs-5">Burgers</span>
              </div>
              <div className=" px-3 py-2 rounded bg-color my-3">
                <img className="img-fluid" src="../assets/thumbnail.png"></img>
                <span className="px-3 fs-5">Burgers</span>
              </div>
            </div> */}
          </div>
          <div className="col-12 col-sm-12 col-md-8 col-lg-9">
            <div className="input-group mb-3 position-relative">
              <div className="search-icon">
                {/* <i className="fa fa-search"></i> */}
                <img
                  alt=""
                  className="im-fluid"
                  src="../assets/search.png"
                ></img>
              </div>
              <input
                type="text"
                className="form-control search-input bg-color"
                placeholder="Search..."
                aria-label="search"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button className="btn btn-search" type="button">
                  Button
                </button>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <NavLink className="text-decoration-none" to="/dishdetail">
                      <h5 className="pt-4">Grilled Fresh Burger</h5>
                      <p className="para-text">
                        There are many variations of Grilled Fresh Burger with
                        great quality ingredients
                      </p>
                    </NavLink>

                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3  py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3  py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3  py-1">
                  <div className="food-card bg-color p-3 bg-color w-100 h-100">
                    <div className="card-img text-center">
                      <img
                        alt=""
                        className="img-fluid"
                        src="../assets/gb.png"
                      ></img>
                    </div>
                    <h5 className="pt-4">Grilled Fresh Burger</h5>
                    <p className="para-text">
                      There are many variations of Grilled Fresh Burger with
                      great quality ingredients
                    </p>
                    <p>€ 5.00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FullMenu;
