import React from "react";

const About = () => {
  return (
    <section className="about py-5">
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 px-0">
            <div className="pe-lg-5">
              <img
                alt=""
                className="img-fluid"
                src="../assets/about-img.webp"
              ></img>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="container px-xxl-5 py-5">
              <h2 className="section-title pt-xxl-5 ">
                <span className="primary-text">About</span> Us
              </h2>
              <div className="content w-75">
                <p className="para-text">
                  Discover exclusive dining deals. Scan, save and savor with
                  WEBATE!
                </p>
                <p className="para-text">
                  Exclusive Offers for Food, Menus and Events.
                </p>
                <p className="para-text py-4 pe-lg-5">
                  Hungry for great deals on your favorite food, menus and
                  events? Look no further! Introducing WEBATE, the ultimate app
                  that connects users with restaurants to bring you incredible
                  offers that last for 24 hours.
                </p>
              </div>
              <button className="btn btn-read-more">
                Read more{" "}
                <span className="px-2">
                  <img alt="" src="../assets/uparrow.png"></img>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
