import React from "react";

const HomeHeader = () => {
  return (
    <section className="home-header bg-color">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-7 col-lg-7 px-md-3">
            <h2 className="header-title py-3 py-md-5">
              Just Come To <span className="primary-text">WEBATE</span> & Get
              Great Offers.
            </h2>
            <p className="para-text w-75">
              Download WEBATE today and unlock a world of exclusive offers,
              menus and events. Elevate your dining experience and discover a
              whole new level of culinary delight!
            </p>
            <div className="btn-links py-3 d-flex">
              <div className="playstore">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.codedesk.webate"
                >
                  <img
                    alt=""
                    className="img-fluid"
                    src="../assets/Playstore.png"
                  ></img>
                </a>
              </div>
              <div className="appstore px-4">
                <img
                  alt=""
                  className="img-fluid"
                  src="../assets/app store.png"
                ></img>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 px-0">
            <div className="hero-img pt-3">
              <img
                alt=""
                className="img-fluid"
                src="../assets/header-img.webp"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHeader;
