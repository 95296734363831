import React from "react";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <section>
      <footer className="footer py-4">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3">
              <NavLink className="text-decoration-none" to="/">
                <div className="text-center lg">
                  <img
                    alt="logo"
                    className="img-fluid"
                    src="../assets/white.png"
                  />
                  <h5 className="logo-text ">webate</h5>
                </div>
              </NavLink>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 links">
              <h3 className="title">Home</h3>
              <div className="py-1">
                <NavLink to={"/"} className="text-decoration-none">
                  Restaurant
                </NavLink>
              </div>
              <div className="py-1">
                <NavLink to={"/"} className="text-decoration-none">
                  Menu
                </NavLink>
              </div>
              <div className="py-1">
                <NavLink to={"/"} className="text-decoration-none">
                  Offer
                </NavLink>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 contact">
              <h3 className="title">Contact</h3>
              <NavLink className="text-decoration-none">
                Contact Developer
              </NavLink>
              <div className="py-1">
                <a href="tel:03166844292" className="text-decoration-none">
                  <img
                    alt=""
                    className="img-fluid pe-2"
                    src="../assets/calling.png"
                  ></img>
                  000 111 22234
                </a>
              </div>
              <div className="py-1">
                <a
                  href="mailto:info@example.com"
                  className="text-decoration-none"
                >
                  <img
                    alt=""
                    className="img-fluid pe-2"
                    src="../assets/Message.png"
                  ></img>
                  info@example.com
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 social">
              <h3 className="title">Our Social Media</h3>

              <div className="d-flex icons py-3">
                <div className="icon-1 pe-2 py-1">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/codedeskstudio/"
                    className="border px-3 pt-3 pb-2 rounded-circle text-white"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </div>
                <div className="icon-1 pe-2 py-1">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/"
                    className="border px-3 pb-2 pt-3 rounded-circle text-white"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
                <div className="icon-1 pe-2 py-1">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/company/codedeskstudio?originalSubdomain=pk"
                    className="border px-3 pt-3 pb-2 rounded-circle text-white"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </div>
              <NavLink className="text-decoration-none" href="#">
                terms & Conditions
              </NavLink>
            </div>
          </div>
        </div>
        <hr className="text-white"></hr>
        <div className="container footer-bottom">
          <div className="row">
            <div className="col-12 col-sm-6">
              Powered by{" "}
              <img
                alt=""
                className="px-2 img-fluid"
                src="../assets/blogo.png"
              ></img>
            </div>
            <div className="col-12 col-sm-6 text-start text-sm-end">
              <p>2023 - WEBATE, All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
