import React from "react";

const Getoffer = () => {
  return (
    <section className="bg-color">
      <div className="container py-lg-5">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img
              alt=""
              className="img-fluid"
              src="../assets/offer-img.webp"
            ></img>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="px-lg-5 py-5">
              <h2 className="section-title pt-lg-5">
                Simple way to get <span className="primary-text">Offers</span>
              </h2>
              <p className="para-text">
                Download WEBATE today and unlock a world of exclusive offers,
                menus and events. Elevate your dining experience and discover a
                whole new level of culinary delight!
              </p>
              <div className="btn-links py-3 d-flex">
                <div className="playstore">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.codedesk.webate"
                  >
                    <img
                      alt=""
                      className="img-fluid"
                      src="../assets/Playstore.png"
                    ></img>
                  </a>
                </div>
                <div className="appstore px-4">
                  <img
                    alt=""
                    className="img-fluid"
                    src="../assets/app store.png"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Getoffer;
