import React from "react";
import RestaurantHeader from "../components/RestaurantComp/RestaurantHeader";
import RestaurantsList from "../components/RestaurantComp/RestaurantsList";
const restaurant = () => {
  return (
    <div>
      <RestaurantHeader />
      <RestaurantsList />
    </div>
  );
};

export default restaurant;
