import React from "react";
import HomeHeader from "../components/HomeComp/HomeHeader";
import PopularRestaurant from "../components/HomeComp/PopularRestaurant";
import DeliciousDishes from "../components/HomeComp/DeliciousDishes";
import ChooseUs from "../components/HomeComp/ChooseUs";
import Reviews from "../components/HomeComp/Reviews";
import About from "../components/HomeComp/About";
import Getoffer from "../components/HomeComp/Getoffer";
const home = () => {
  return (
    <div>
      <HomeHeader />
      <PopularRestaurant />
      <DeliciousDishes />
      <ChooseUs />
      <Reviews />
      <About />
      <Getoffer />
    </div>
  );
};

export default home;
