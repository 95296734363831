import React from "react";

const RestaurantHeader = () => {
  return (
    <section className="home-header bg-color">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-7 col-lg-7 px-md-3">
            <div className="pt-5">
              <img
                alt=""
                className="img-fluid"
                src="../assets/bbchicken.png"
              ></img>
              <p className="para-text w-75 pt-lg-5">
                Download WEBATE today and unlock a world of exclusive offers,
                menus and events. Elevate your dining experience and discover a
                whole new level of culinary delight!
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 px-0">
            <div className="hero-img pt-3">
              <img
                alt=""
                className="img-fluid"
                src="../assets/header-img.webp"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RestaurantHeader;
