import React from "react";

const Reviews = () => {
  return (
    <section className="reviews-main bg-color">
      <div className="container py-lg-5">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <img
              alt=""
              className="img-fluid"
              src="../assets/review-img.webp"
            ></img>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="container px-lg-5">
              <h2 className="section-title pt-5 mt-lg-5">
                Our <span className="primary-text">Customer</span> Says About
                Us.
              </h2>
              <p className="para-text">
                An effective advertising slogan it gives an accurate picture of
                what your Restaurants Business is all about.
              </p>

              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner position-relative">
                  <div className="carousel-item active">
                    <div className="review-card pb-5 ">
                      <div className="d-flex">
                        <div className="review-img">
                          <img
                            alt=""
                            className="img-fluid"
                            src="../assets/men.png"
                          ></img>
                        </div>
                        <div className="title py-1 px-3">
                          <h4>Abram Geidt</h4>
                          <p className="para-text fs-6">Food Specialist</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="ratings">
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star-half-o"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star-o"></i>
                          </span>
                        </div>
                        <div className="numbers">
                          <p className="px-4">4.5</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="review-card pb-5">
                      <div className="d-flex">
                        <div className="review-img">
                          <img
                            alt=""
                            className="img-fluid"
                            src="../assets/men.png"
                          ></img>
                        </div>
                        <div className="title py-1 px-3">
                          <h4>Muzamil Niaz</h4>
                          <p className="para-text fs-6">Cook</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="ratings">
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star-half-o"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star-o"></i>
                          </span>
                        </div>
                        <div className="numbers">
                          <p className="px-4">4.5</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="review-card pb-5">
                      <div className="d-flex">
                        <div className="review-img">
                          <img
                            alt=""
                            className="img-fluid"
                            src="../assets/men.png"
                          ></img>
                        </div>
                        <div className="title py-1 px-3">
                          <h4>Faisal Arshad</h4>
                          <p className="para-text fs-6">Manager</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="ratings">
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star-half-o"></i>
                          </span>
                          <span className="pe-1">
                            <i className="fa fa-star-o"></i>
                          </span>
                        </div>
                        <div className="numbers">
                          <p className="px-4">4.5</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
