import React from "react";
import { NavLink } from "react-router-dom";
const Navbar = () => {
  return (
    <section className="shadow">
      <div className="container-fluid">
        <div className="navbar-main bg-white container">
          <div className="row">
            <nav className="navbar navbar-expand-lg ms-auto py-3">
              <div className="col-2 col-sm-1 col-md-2 d-block d-sm-block d-md-block d-lg-none">
                <button
                  className="btn btn-outline-secondary"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#side-navbar"
                  type="button"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>

              <div className="col-2 col-sm-3 col-md-4 col-lg-2 col-xl-2 ">
                <NavLink className="text-decoration-none" to="/">
                  <div className="text-center navbar-brand lg">
                    <img
                      alt="logo"
                      className="img-fluid"
                      src="../assets/logo.png"
                    />
                    <h5 className="logo-text ">webate</h5>
                  </div>
                </NavLink>
              </div>

              <div className="col-lg-6 col-xl-6 d-none d-sm-none d-md-none d-lg-block">
                <div
                  className="collapse navbar-collapse justify-content-center"
                  id="navbarNav"
                >
                  <ul className="navbar-nav align-items-center">
                    <li className="nav-item main-nav-link px-4">
                      <NavLink to="/" className="nav-link">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item main-nav-link px-4">
                      <NavLink to="/restaurant" className="nav-link">
                        Restaurant
                      </NavLink>
                    </li>
                    <li className="nav-item main-nav-link px-4">
                      <NavLink to="contact" exact className="nav-link ">
                        Contact Developer
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-7 col-sm-7 col-md-6 col-lg-4 col-xl-4 justify-content-end -end d-flex">
                <div className="btn-group languages">
                  <button
                    type="button"
                    className="btn bg-white languages-btn "
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    aria-expanded="false"
                  >
                    <img
                      alt=""
                      className="img-fluid px-2"
                      src="../assets/world.png"
                    ></img>
                    <span className="d-none d-sm-inline-block">English</span>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                    <li>
                      <button className="dropdown-item" type="button">
                        <img
                          alt=""
                          className="img-fluid px-2"
                          src="../assets/french.png"
                        ></img>
                        French
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        <img
                          alt=""
                          className="img-fluid px-2"
                          src="../assets/germany.png"
                        ></img>
                        Germany
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        <img
                          alt=""
                          className="img-fluid px-2"
                          src="../assets/spain.png"
                        ></img>
                        Spanish
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        <img
                          alt=""
                          className="img-fluid px-2"
                          src="../assets/japan.png"
                        ></img>
                        Japanese
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        <img
                          alt=""
                          className="img-fluid px-2"
                          src="../assets/port.png"
                        ></img>
                        Portugues
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        <img
                          alt=""
                          className="img-fluid px-2"
                          src="../assets/mand.png"
                        ></img>
                        Mandarin Chinese
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        <img
                          alt=""
                          className="img-fluid px-2"
                          src="../assets/arabic.png"
                        ></img>
                        Arabic
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="px-lg-3">
                  <button className="btn login-btn" type="button">
                    <span className="d-none d-sm-inline-block text-white">
                      Restaurant
                    </span>{" "}
                    Login
                  </button>
                </div>
              </div>
              <div className="col-12 d-block d-sm-block d-md-block d-lg-none">
                <div className="offcanvas offcanvas-start" id="side-navbar">
                  <div className="offcanvas-header">
                    <NavLink className="navbar-brand " to="/">
                      <div className="text-center">
                        <img
                          alt=""
                          className="img-fluid"
                          src="../assets/logo.png"
                        />
                        <h5 className="logo-text">webate</h5>
                      </div>
                    </NavLink>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                    ></button>
                  </div>
                  <div className="offcanvas-body">
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item main-nav-link px-4">
                        <NavLink to="/" className="nav-link">
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item main-nav-link px-4">
                        <NavLink to="/restaurant" className="nav-link">
                          Restaurant
                        </NavLink>
                      </li>
                      <li className="nav-item main-nav-link px-4">
                        <NavLink to="contact" exact className="nav-link ">
                          Contact Developer
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
